@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  overflow-x: hidden;
}

.hammersmith-one-regular {
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.raleway {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.active {
  color: black;
}
.active-link {
  font-weight: bold;
  color: yellow;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #f0f0f0;
    color: #333;
  }
}

@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
}
